import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from 'moment-timezone';

import Cookies from 'universal-cookie';

import { utcTime, localTime } from "../../MyCalendarGeneralItems";
import { getMemberDashboardDetails } from "../../../../AppRedux/actions/MemberDashboard";
import data from "../../../utilities/content/statusAndRoleManagement.json";
import ModalOuter from "../../../utilities/modals/ModalOuter";
import OutsideClickCloseContainer from "../../../utilities/OutsideClickCloseContainer";
import { MemberNavbarDownArrow } from "../../../utilities/svg";
import FormBackAndContinueButton from "../../MemberRegisterForms/FormBackAndContinueButton";
import {
  AppTimePickerContainer,
  ModalDateContainer,
  ModalHeader,
  convertTimeTo12HoursFromat,
  ModalErrorMessage,
  ModalCancellationText,
  CancellationPolicyContent,
  relativeTime
} from "../../MyCalendarGeneralItems";
import {
  getDifferenceBetweenTwoTimes,
  onMentorAvailableTimeSlotClick,
} from "./MeetingBookingModalFunctions";
import {
  getMemberCalendarPreference,
  scheduleMeetingWithMentor,
  viewMemberMeeting,
} from "../../../../AppRedux/actions/MemberCalendar";
import {
  deafultToast,
  getMemberDashboardDataFromLocalStorage,
  infoToast,
  scrollToTop,
  successToast,
} from "../../../utilities";

const MeetingBookingModal = ({
  state,
  setState,
  dayInfo,
  slotsData,
  meetingBooked,
  calendarCurrentMonthAndYear,
  memberPurchasedMinutes,
}) => {
  const dispatch = useDispatch(),
    [selectedTimeSlot, setSelectedTimeSlot] = useState(false),
    [modalError, setModalError] = useState(""),
    [selectedDateAvailableSlots, setSelectedDateAvailableSlots] = useState([]),
    [toSlots, setToSlots] = useState([]),
    [openCancellationPolicyModal, setOpenCancellationPolicyModal] =
      useState(false),
    [openFromTimeDropdown, setOpenFromTimeDropdown] = useState(false),
    [openToTimeDropdown, setOpenToTimeDropdown] = useState(false),
    { mentor } = getMemberDashboardDataFromLocalStorage(),
    [formData, setFormData] = useState({
      fromTime: "",
      toTime: "",
    }),
    [formError, setFormError] = useState({
      fromTime: "",
      toTime: "",
    }),
    {
      scheduleMeetingWithMentorBegin,
      scheduleMeetingWithMentorSuccessData,
      scheduleMeetingWithMentorFailureData,
    } = useSelector(({ memberCalendar }) => memberCalendar),
    { getMemberDashboardDetailsBegin, getMemberDashboardDetailsSuccessData } =
      useSelector(({ memberDashboard }) => memberDashboard);

  const date      = dayInfo.dateStr ? dayInfo.dateStr : dayInfo?.event?.startStr;
  const cookies   = new Cookies(null, { path: '/' });
  const timezone  = cookies.get('geolocation');
  moment.tz.setDefault(timezone);

  useEffect(() => {
    if (selectedDateAvailableSlots && selectedTimeSlot) {
      const { fromTime, toTime } = formData;
      if (fromTime === "") {
        let fromTime;
        selectedDateAvailableSlots
          ?.find(({ id }) => id === selectedTimeSlot)
          ?.slots.map(({ start }) => start)
          .forEach((slot) => {
            let newDate = moment(date).format("YYYY-MM-DD")
            let startTime = moment(newDate + " " + slot)
            if (
              moment(new Date(startTime)).isAfter()
            ) {
              if (!fromTime) {
                fromTime = slot;
              }
            }
          });

        setFormData((formData) => {
          return {
            ...formData,
            fromTime: fromTime,
          };
        });
      }
      if (toTime === "") {
        let toTime;
        selectedDateAvailableSlots
          ?.find(({ id }) => id === selectedTimeSlot)
          .slots.map(({ end }) => end)
          .forEach((slot, index, slots) => {
            let newDate = moment(date).format("YYYY-MM-DD")
            let endTime = moment(newDate + " " + slot)

            
            if (
              moment(new Date(endTime)).isAfter()
            ) {
              if (!toTime) {
                if (index === 0) {
                  toTime = slot;
                } else {
                  toTime = slots[index + 1];
                }
              }
            }
          });

        setFormData((formData) => {
          return {
            ...formData,
            toTime: toTime,
          };
        });
      }
    }
  }, [formData, selectedDateAvailableSlots, selectedTimeSlot, toSlots, date]);

  useEffect(() => {
    if (scheduleMeetingWithMentorSuccessData) {
      dispatch(getMemberDashboardDetails());
    }
  }, [scheduleMeetingWithMentorSuccessData, dispatch]);

  useEffect(() => {
    if (
      getMemberDashboardDetailsSuccessData ||
      scheduleMeetingWithMentorFailureData
    ) {
      // close modal when user submitting
      scrollToTop("50", "16.66");
      setState(false);

      dispatch(
        getMemberCalendarPreference(
          calendarCurrentMonthAndYear.month,
          calendarCurrentMonthAndYear.year,
          mentor.UserMentor.userId
        )
      );
      dispatch(viewMemberMeeting(1, data.MEETING.STATUS.PENDING));
    }
    if (getMemberDashboardDetailsSuccessData)
      successToast("Meeting Booked Successfully!");

    if (scheduleMeetingWithMentorFailureData)
      infoToast(scheduleMeetingWithMentorFailureData.message);
  }, [
    getMemberDashboardDetailsSuccessData,
    scheduleMeetingWithMentorFailureData,
    setState,
    mentor,
    dispatch,
    calendarCurrentMonthAndYear,
  ]);

  useEffect(() => {
    // setting mentor availble time slots of selected date if there is any
    slotsData?.data?.forEach((event) => {
      if (
        event.date.includes(
          dayInfo.dateStr ? dayInfo.dateStr : dayInfo?.event?.startStr
        )
      ) {
        const { id, startTime, endTime, date, slots } = event;

        let startTimeLocal = moment(startTime).format("HH:mm");
        let endTimeLocal = moment(endTime).format("HH:mm");

        let utcSlots = [];

        slots.map(({ id, start, end }, index) => {
          utcSlots[index] = {
            id: id,
            start: moment(start).format("HH:mm"),
            end: moment(end).format("HH:mm"),
          };
          return "";
        });

        if (
          moment(startTimeLocal, "h:mm").isAfter(moment(endTimeLocal, "h:mm"))
        ) {
          // console.log(utcSlots);
          utcSlots = utcSlots.filter(
            (value) =>{
              return moment(value.end, "h:mm").isSameOrAfter(moment(endTimeLocal, "h:mm")) && moment(value.start, "h:mm").isSameOrAfter(moment(startTimeLocal, "h:mm"))
            }
          );
        }

        const slotsEndingTime = moment.utc(new Date(date))
          .hour(endTimeLocal?.split(":")[0])
          .minute(endTimeLocal?.split(":")[1]);

        var duration = moment.duration({ m: 15 }); // member can only select slot before 30minutes

        // console.log(" subtract ending time ");
        // console.log(date);
        // console.log( 'moment(new Date(date)).format("YYYY-MM-DD HH:mm" ) ');
        // console.log( moment.utc(new Date(date)).format("YYYY-MM-DD HH:mm" ) );
        // console.log("endTimeLocal");
        // console.log(endTimeLocal);
        // console.log('slotsEndingTime.format("YYYY-MM-DD HH:mm")');
        // console.log(slotsEndingTime.format("YYYY-MM-DD HH:mm"));

        const data = {
          startTime: startTimeLocal,
          endTime: moment(startTimeLocal, "h:mm").isAfter(
            moment(endTimeLocal, "h:mm")
          )
            ? "23:59"
            : endTimeLocal,
          id,
          isExpired: slotsEndingTime.subtract(duration).isBefore(),
          date,
          timezone: timezone,
          slots: utcSlots,
        };

        setSelectedDateAvailableSlots((selectedDateAvailableSlots) => [
          ...selectedDateAvailableSlots,
          data,
        ]);
      }
    });
  }, [slotsData, dayInfo, setSelectedDateAvailableSlots]);

  useEffect(() => {
    if (selectedDateAvailableSlots && selectedTimeSlot) {
      // perf bottleneck
      const slotsSet = new Set();
      selectedDateAvailableSlots
        ?.find(({ id }) => id === selectedTimeSlot)
        .slots.map(({ end }) => end)
        .every((slot, index, slots) => {
          slotsSet.add(slot);
          return true;
        });

      setToSlots(Array.from(slotsSet));
    }
  }, [selectedDateAvailableSlots, selectedTimeSlot]);

  const onChaneInput = () => {
    setFormError({
      ...formError,
      fromTime: "",
      toTime: "",
    });
    setModalError("");
  };

  const TimePicker = () => {
    const fromSlots = selectedDateAvailableSlots
      ?.find(({ id }) => id === selectedTimeSlot)
      .slots.map(({ start }) => start);
    const allToSlots = selectedDateAvailableSlots
      ?.find(({ id }) => id === selectedTimeSlot)
      .slots.map(({ end }) => end);

    const fromAndToDropdowns = [
      {
        label: "From :",
        errorMessage: formError.fromTime,
        value: convertTimeTo12HoursFromat(formData.fromTime),
        open: openFromTimeDropdown,
        slots: fromSlots,
        openFunction: setOpenFromTimeDropdown,
        dropdownCondition: (time) => {
          if (
            moment.utc(new Date(date))
              .hour(time.split(":")[0])
              .minute(time.split(":")[1])
              .isAfter() &&
            getDifferenceBetweenTwoTimes(
              time,
              selectedDateAvailableSlots?.find(
                ({ id }) => id === selectedTimeSlot
              ).endTime
            ) >= 15
          )
            return true;
          else return false;
        },
        optionOnclick: (time, fromIndex) => {
          setToSlots([]);

          allToSlots.slice(fromIndex).every((slot, index, slots) => {
            if (slots[index - 1]) {
              if (getDifferenceBetweenTwoTimes(slots[index - 1], slot) !== 15) {
                return false;
              } else {
                setToSlots((toSlots) => [...toSlots, slot]);
                return true;
              }
            } else {
              setToSlots((toSlots) => [...toSlots, slot]);
              return true;
            }
          });

          setFormData({
            ...formData,
            fromTime: time,
            toTime: allToSlots[fromIndex],
          });

          onChaneInput();
        },
      },
      {
        label: "To :",
        errorMessage: formError.toTime,
        value: convertTimeTo12HoursFromat(formData.toTime),
        slots: toSlots,
        open: openToTimeDropdown,
        openFunction: setOpenToTimeDropdown,
        dropdownCondition: (time) => {
          if (
            moment.utc(new Date(date))
              .hour(time.split(":")[0])
              .minute(time.split(":")[1])
              .isAfter() &&
            //  lessthan member purchased minutes
            getDifferenceBetweenTwoTimes(formData.fromTime, time) <=
            memberPurchasedMinutes &&
            getDifferenceBetweenTwoTimes(formData.fromTime, time) >= 15
          ) {
            return true;
          } else {
            return false;
          }
        },

        optionOnclick: (time) => {
          setFormData({
            ...formData,
            toTime: time,
          });
          onChaneInput();
        },
      },
    ];

    return (
      <>
        {fromAndToDropdowns.map(
          (
            {
              label,
              errorMessage,
              open,
              slots,
              openFunction,
              value,
              dropdownCondition,
              optionOnclick,
            },
            index
          ) => (
            <OutsideClickCloseContainer
              classNames="flex flex-col w-1/2"
              setState={openFunction}
              key={index}
            >
              <AppTimePickerContainer
                label={label}
                errorMessage={errorMessage}
                classNames="relative cursor-pointer z-10"
                onClick={() => openFunction(!open)}
              >
                <div className="flex items-center gap-3">
                  <p className="font-bold">{value}</p>
                  <MemberNavbarDownArrow className="stroke-black" />
                </div>
                {open && (
                  <div
                    className="memberDropdownList pt-0 top-full right-0 overflow-y-auto rounded-t-none"
                    style={{ minWidth: "150px", maxHeight: "300px" }}
                  >
                    {slots.map((slot, index) => (
                      <div key={index}>
                        {dropdownCondition(slot) && (
                          <div
                            onClick={() => optionOnclick(slot, index)}
                            className="font-bold memberDropdownListItem"
                          >
                            {convertTimeTo12HoursFromat(slot)}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </AppTimePickerContainer>
            </OutsideClickCloseContainer>
          )
        )}
      </>
    );
  };

  const customeFormValidation = () => {
    if (!selectedTimeSlot) {
      setModalError("Please select a time slot for your session.");
      return true;
    }
  };

  // on submit selected time slot for booking
  const onfixBookingButtonClick = (e) => {
    e.preventDefault();

    if (!customeFormValidation()) {
      const meetingDate = dayInfo.dateStr
        ? dayInfo.dateStr
        : dayInfo?.event?.startStr;
      const { fromTime, toTime } = formData;
      const MeetingStartingTime = moment(meetingDate + " " + fromTime);
      const MeetingEndTime = moment(meetingDate + " " + toTime );
      if (MeetingStartingTime.isBefore()) {
        infoToast("Please select an upcoming slot for your meeting");
        setSelectedTimeSlot(false);
      } else {
        const bookingData = {
          mentorId: mentor.UserMentor.id,
          slotId: selectedTimeSlot,
          meetingDate: meetingDate,
          // fromTime: utcTime(fromTime),
          // toTime: utcTime(toTime),
          fromTime: moment(MeetingStartingTime).format('YYYY-MM-DD HH:mm:ss'),
          toTime: moment(MeetingEndTime).format('YYYY-MM-DD HH:mm:ss'),
          timezone : timezone,
          objMentor : mentor
        };
        
        dispatch(scheduleMeetingWithMentor(bookingData));
      }
    }
  };

  return (
    <>
      <ModalOuter
        state={state}
        setState={setState}
        classNames="p-7 text-left animate-modalInitial min-w-200px 500px:min-w-470px"
      >
        {openCancellationPolicyModal ? (
          <CancellationPolicyContent
            goBackClick={() =>
              setOpenCancellationPolicyModal(!openCancellationPolicyModal)
            }
          />
        ) : (
          <div className="animate-dashboardInitial">
            <ModalHeader
              heading="Book your meeting"
              description="Book a meeting with your Coach"
            />
            <ModalDateContainer dayInfo={dayInfo} />
            {modalError !== "" && (
              <ModalErrorMessage
                modalError={modalError}
                setModalError={setModalError}
              />
            )}
            <h1 className=" text-base font-semibold mb-2">
              Available time slots
            </h1>

            <div className="grid grid-cols-2 gap-5 mb-4">
              {selectedDateAvailableSlots.map(
                ({ startTime, endTime, id, isExpired }) => (
                  <div
                    key={id}
                    className={`text-sm 500px:text-lg rounded-3xl py-2 px-2 flex flex-row items-center justify-around   ${isExpired
                      ? "cursor-not-allowed"
                      : "buttonHover activeState cursor-pointer transition-all ease-in duration-200"
                      }`}
                    style={{
                      backgroundColor:
                        id === selectedTimeSlot ? "#5F9778" : "#fff",
                      color:
                        id === selectedTimeSlot
                          ? "#fff"
                          : isExpired
                            ? "#BCBCBC"
                            : "#5F9778",
                      border:
                        id === selectedTimeSlot
                          ? "1px solid transparent"
                          : isExpired
                            ? "1px solid #BCBCBC"
                            : "1px solid #5F9778",
                    }}
                    onClick={() => {
                      
                      if (!isExpired) {
                        onMentorAvailableTimeSlotClick(
                          id,
                          setFormData,
                          selectedTimeSlot,
                          setSelectedTimeSlot
                        );
                        setModalError("");
                      } else {
                        deafultToast("Please select an upcoming time slot");
                        setTimeout(() => {
                          toast.clearWaitingQueue();
                        }, 2000);
                      }
                    }}
                  >
                    {convertTimeTo12HoursFromat(startTime)} -{" "}
                    {convertTimeTo12HoursFromat(endTime)}
                  </div>
                )
              )}
            </div>
            {!meetingBooked && memberPurchasedMinutes !== 0 ? (
              <p
                className="font-semibold text-sm mb-2"
                style={{ color: "#828282" }}
              >
                Choose your timeslot for meeting
              </p>
            ) : (
              ""
            )}

            {memberPurchasedMinutes <= 0 ? (
              <p className="p-3 bg-brandBlue text-white text-sm xl:text-base rounded-md opacity-70 mb-3 animate-mobileMenu">
                Sorry! Please purchase minutes for scheduling call with mentor
              </p>
            ) : (
              <form onSubmit={(e) => onfixBookingButtonClick(e)}>
                {/* Booking time input  */}
                {selectedTimeSlot ? (
                  <div className="flex flex-row items-center w-full mb-5 gap-3 animate-navbar">
                    <TimePicker />
                  </div>
                ) : (
                  ""
                )}

                {!meetingBooked ? (
                  <FormBackAndContinueButton
                    buttonText="Schedule"
                    backButtonText="Cancel"
                    onBackButtonClick={() => setState(false)}
                    actionBegin={
                      scheduleMeetingWithMentorBegin ||
                      getMemberDashboardDetailsBegin
                    }
                    buttonClass="py-2 text-base"
                  />
                ) : (
                  <p className="p-3 bg-brandBlue text-white text-sm xl:text-base rounded-md opacity-70 mb-3 animate-mobileMenu">
                    Sorry! You can't able to book multiple slots, Please try
                    after you complete your current meeting.
                  </p>
                )}
                <ModalCancellationText
                  onClick={() =>
                    setOpenCancellationPolicyModal(!openCancellationPolicyModal)
                  }
                  classNames="mt-0 lg:mt-4"
                />
              </form>
            )}
          </div>
        )}
      </ModalOuter>
    </>
  );
};

export default MeetingBookingModal;
