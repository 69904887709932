const config = {
  development: {
    BASE_STORIES: "https://www.gritandsoul.net",
    // BASE_API: "http://localhost:4000",
  //  BASE_API: "https://merity-api.mymerity.com",
  BASE_API: "https://merity-api-stage.devtomaster.com",
  },
  production: {
    BASE_STORIES: "https://www.gritandsoul.net",
    // BASE_API: "http://localhost:4000",
    // BASE_API: "https://merity-api.mymerity.com",
    BASE_API: "https://merity-api-stage.devtomaster.com",
  },
};

module.exports = config;
